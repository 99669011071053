import io from 'socket.io-client';
import {
  setSignalerState,
  SignalerState,
  setVideoState,
  VideoState,
} from '../store/interface';

// eslint-disable-next-line import/no-cycle
import { AppDispatch } from '../store';

import { getInstanceId } from './instanceId';

import { UserInfo } from './cognito';

interface SocketJoin {
  roomName: string;
  stunOn: boolean;
  instanceId: string;
  userType: 'patron' | 'interpreter';
  videoEnabled: boolean;
}

interface SocketDupe {
  peerId: string;
}

export interface SocketInfo {
  info: string;
}

export interface SocketId {
  localId: string;
}

export interface Location {
  longitude: number;
  latitude: number;
}
export interface SocketInvite {
  peerId: string;
  avail: number;
  occupied: number;
  location?: Location;
  userInfo?: UserInfo;
}

interface SocketPulsation {
  interval: number;
}

interface SurrogateHandler {
  first: string;
  last: string;
  username: string;
  email: string[];
  phone: string[];
}
interface SurrogateAttributes {
  appName: string;
  originator: string;
  host: string;
  handlers: SurrogateHandler[];
}

interface SocketCreateOffer {
  ice: RTCIceServer;
  localId: string;
  surrogateAttributes: SurrogateAttributes;
}

export interface SocketMute {
  roomName?: string;
  peerId?: string;
  video: boolean;
  audio: boolean;
}

export interface SocketDismiss {
  peerId: string;
  roomName: string;
}

export interface SocketCandidate {
  candidate: RTCIceCandidateInit | null;
  roomName: string;
  fromId?: string;
  peerId: string;
}

export interface SocketSDP {
  sdp: RTCSessionDescription;
  peerId: string;
}

export const connect = (
  signalerUrl: string,
  roomName: string,
  token: string,
  dispatch: AppDispatch
) => {
  const socketConfig = {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    query: {
      token,
    },
  };

  const newSocket = io(signalerUrl, socketConfig);

  const instanceId = getInstanceId();
  console.log('the instance ID is', instanceId);

  const info: SocketJoin = {
    roomName,
    instanceId,
    stunOn: true,
    userType: 'interpreter',
    videoEnabled: true,
  };

  newSocket.on('connect', () => {
    console.log('connected!!');
    dispatch(setSignalerState(SignalerState.CONNECTED));
    newSocket.emit('enterVRI', info);
  });

  newSocket.on('dupe', (data: SocketDupe) => {
    // const { peerId } = data;
    newSocket.emit('kick', data);
  });

  newSocket.on('info', (message: string) => {
    switch (message) {
      case 'dupeKicked':
        newSocket.emit('enterVRI', info);
        break;
      case 'dupeKept':
        break;
      default:
        console.log(message);
    }
  });

  newSocket.on('error', (err: any) => {
    console.log('signaler error', err);
    if (err === 'token_verification_failed') {
      dispatch(setSignalerState(SignalerState.TOKEN_AUTH_FAILED));
    }
  });

  newSocket.on('disconnect', () => {
    console.log('disconnected');
    dispatch(setSignalerState(SignalerState.DISCONNECTED));
  });

  newSocket.on('exitVRI', () => {
    console.log('exitVRIed');
    dispatch(setVideoState(VideoState.NOT_IN_CALL));
  });

  newSocket.on('connect_error', () => {
    console.log('connect error');
    dispatch(setSignalerState(SignalerState.CONNECTION_ERROR));
  });

  newSocket.on('connect_timeout', () => {
    console.log('connect_timeout');
    dispatch(setSignalerState(SignalerState.CONNECTION_ERROR));
  });

  newSocket.on('reconnect', () => {
    console.log('reconnect');
    dispatch(setSignalerState(SignalerState.CONNECTED));
  });

  newSocket.on('reconnect_error', () => {
    console.log('reconnect_error');
    dispatch(setSignalerState(SignalerState.CONNECTION_ERROR));
  });

  return newSocket;
};

export const startHeartbeat = (
  socket: SocketIOClient.Socket,
  interval: number
) => {
  const sendHeartbeat = () => {
    socket.emit('beat');
  };

  return setInterval(sendHeartbeat, interval);
};

export const stopHeartbeat = (funcId: NodeJS.Timeout): void => {
  clearInterval(funcId);
};

export const receivePulsation = (
  socket: SocketIOClient.Socket,
  handler: (interval: number) => void
): void => {
  socket.on('pulsation', (data: SocketPulsation) => {
    const { interval } = data;
    handler(interval);
  });
};

export const receiveInvite = (
  socket: SocketIOClient.Socket,
  handler: (
    peerId: string,
    numAvail: number,
    numOccupied: number,
    location: Location | undefined,
    userInfo: UserInfo | undefined
  ) => void
) => {
  socket.off('invite');
  socket.on('invite', (data: SocketInvite) => {
    const { peerId, avail, occupied, location, userInfo } = data;
    console.log('invite stuff', data);
    handler(peerId, avail, occupied, location, userInfo);
  });
};

export const sendIVR = (
  socket: SocketIOClient.Socket,
  roomName: string,
  response: 'accept' | 'reject' | 'miss',
  peerId: string
) => {
  console.log('ivr', { response, roomName, peerId });
  socket.emit('ivr', { response, roomName, peerId });
};

export const receiveCandidate = (
  socket: SocketIOClient.Socket,
  handler: (candidate: RTCIceCandidateInit | null) => void
) => {
  socket.off('candidate');
  socket.on('candidate', (data: SocketCandidate) => {
    const { candidate } = data;
    handler(candidate);
  });
};

export const receiveCreateOffer = (
  socket: SocketIOClient.Socket,
  handler: (
    config: RTCConfiguration,
    surrogateAttributes: SurrogateAttributes
  ) => void
) => {
  socket.off('createOffer');
  socket.on('createOffer', (message: SocketCreateOffer) => {
    const { ice, surrogateAttributes } = message;

    if (ice !== undefined) {
      const { urls } = ice as { urls: string[] };
      const newUrls = urls.filter(url => !url.includes('stun'));
      ice.urls = newUrls;
      const iceConfig = { iceServers: [ice] };
      handler(iceConfig, surrogateAttributes);
    } else {
      console.log('falling back to google STUN');
      const iceConfig = {
        iceServers: [
          {
            urls: [
              'stun:stun.l.google.com:19302',
              'stun:stun1.l.google.com:19302',
              'stun:stun2.l.google.com:19302',
              'stun:stun3.l.google.com:19302',
            ],
          },
        ],
      };
      handler(iceConfig, surrogateAttributes);
    }
  });
};

export const receiveSDP = (
  socket: SocketIOClient.Socket,
  handler: (sdp: RTCSessionDescription) => Promise<void>
) => {
  socket.off('sdp');
  socket.on('sdp', (data: SocketSDP) => {
    handler(data.sdp);
  });
};

export const sendCandidate = (
  socket: SocketIOClient.Socket,
  candidate: RTCIceCandidateInit | null,
  roomName: string,
  peerId: string
) => {
  const message: SocketCandidate = {
    roomName,
    peerId,
    candidate,
  };

  socket.emit('candidate', message);
};

export const sendSDP = (
  socket: SocketIOClient.Socket,
  sdp: RTCSessionDescription,
  peerId: string
) => {
  const message: SocketSDP = {
    peerId,
    sdp,
  };
  socket.emit('sdp', message);
};

export const sendDismiss = (
  socket: SocketIOClient.Socket,
  peerId: string,
  roomName: string
) => {
  const message: SocketDismiss = {
    peerId,
    roomName,
  };

  socket.emit('dismiss', message);
};

interface MuteFlags {
  video: boolean;
  audio: boolean;
}

export const receiveMute = (
  socket: SocketIOClient.Socket,
  handler: (peerId: string, flags: MuteFlags) => void
) => {
  socket.off('mute');
  socket.on('mute', (data: SocketMute) => {
    const { peerId, video, audio } = data;

    const flags = {
      video,
      audio,
    };

    handler(peerId || '', flags);
  });
};

export const sendMute = (
  socket: SocketIOClient.Socket,
  roomName: string,
  flags: MuteFlags
) => {
  const message: SocketMute = {
    roomName,
    audio: flags.audio,
    video: flags.video,
  };

  socket.emit('mute', message);
};

import React from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../../store';
import { VideoState } from '../../store/interface';
import fade from '../../common/transitions/fade.module.css';

import styles from './styles.module.css';

const ConnectionOperational = (): JSX.Element => {
  const { videoState, localMute } = useSelector(
    (state: RootState) => state.interface
  );

  const show =
    videoState === VideoState.ON_STANDBY ||
    videoState === VideoState.IS_RINGING ||
    videoState === VideoState.IS_PICKING_UP ||
    videoState === VideoState.IVR_ACCEPTED ||
    localMute;

  return (
    <CSSTransition
      in={show}
      classNames={fade}
      timeout={300}
      mountOnEnter
      unmountOnExit
    >
      <div className={styles.container}>
        <div className={styles.text}>
          <FontAwesomeIcon icon={faPlug} className={styles.icon} />
          {videoState === VideoState.ON_STANDBY &&
            'You are ready to accept calls!'}
          {videoState === VideoState.IS_RINGING && 'A patron is ringing!'}
          {videoState === VideoState.IVR_ACCEPTED &&
            'Initializing connection with a patron!'}
          {videoState === VideoState.IS_PICKING_UP &&
            'Finalizing connection with a patron!'}
          {!localMute && videoState === VideoState.IN_CALL && 'Have fun!'}
          {videoState === VideoState.NOT_IN_CALL && 'Goodbye!'}
          {localMute && 'You are muted. Press UNMUTE to resume your session'}
        </div>
      </div>
    </CSSTransition>
  );
};

export default ConnectionOperational;

import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import interfaceReducer from './interface';
// eslint-disable-next-line import/no-cycle
import webrtcReducer from './webrtc';

const store = configureStore({
  reducer: { interface: interfaceReducer, webrtc: webrtcReducer },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;

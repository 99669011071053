type TestPasswordResult =
  | 'success'
  | 'noLowerCase'
  | 'noUpperCase'
  | 'tooShort'
  | 'noNumber'
  | 'mismatch';

export const errorMessage = {
  noLowerCase: 'Your password must contain at least one lowercase letter.',
  noUpperCase: 'Your password must contain at least one uppercase letter.',
  tooShort: 'Your password must be at least 8 characters long.',
  noNumber: 'Your password must contain at least one number.',
  mismatch: 'Your passwords do not match.',
};

export const hasLowerCase = (str: string): boolean => str.toUpperCase() !== str;

export const hasUpperCase = (str: string): boolean => str.toLowerCase() !== str;

export const lengthTest = (str: string): boolean => str.length >= 8;

export const hasNumber = (str: string): boolean => /[0-9]/.test(str);

export const testPassword = (
  password: string,
  confirmPassword: string
): TestPasswordResult => {
  if (!lengthTest(password)) {
    return 'tooShort';
  }

  if (!hasLowerCase(password)) {
    return 'noLowerCase';
  }

  if (!hasUpperCase(password)) {
    return 'noUpperCase';
  }

  if (!hasNumber(password)) {
    return 'noNumber';
  }

  if (password !== confirmPassword) {
    return 'mismatch';
  }

  return 'success';
};

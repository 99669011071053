import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import store from './store';

import 'typeface-montserrat';
import App from './components/App';
import CognitoProvider from './components/Login/CognitoProvider';

import './index.css';

// ========================================

ReactDOM.render(
  <Provider store={store}>
    <CognitoProvider>
      <App />
    </CognitoProvider>
  </Provider>,
  document.getElementById('root')
);

import React, { useState } from 'react';
import Context, { AuthState, defaultValues } from '../../context/cognito';

interface Props {
  children: JSX.Element;
}

const CognitoProvider = (props: Props) => {
  const { children } = props;
  const [authState, setAuthState] = useState<AuthState>(
    defaultValues.authState
  );
  const [user, setUser] = useState<any>(defaultValues.user);

  const isAuthed = authState === AuthState.AUTHED;

  return (
    <Context.Provider
      value={{ authState, user, setAuthState, setUser, isAuthed }}
    >
      {children}
    </Context.Provider>
  );
};

export default CognitoProvider;

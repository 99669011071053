import React, { useState, useCallback, useContext } from 'react';
import cognitoContext, { AuthState } from '../../context/cognito';

import useTimeout from '../../hooks/useTimeout';
import { createNewPassword } from '../../utils/cognito';
import TextInput from './TextInput';
import MessageBox from './MessageBox';
import PasswordPolicyGroup from './PasswordPolicyGroup';

import { testPassword, errorMessage } from '../../utils/password';

import styles from './styles.module.css';

const disableSubmit = (e: any) => {
  e.preventDefault();
};

const MakeNewPassword = (): JSX.Element => {
  const { authState, setAuthState, user, setUser } = useContext(cognitoContext);

  const [attempting, setAttempting] = useState<boolean>(false);

  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [messageFirstLine, setMessageFirstLine] = useState<string>('');

  const showMessageBox =
    authState === AuthState.NEED_NEW_PASSWORD_INVALID || attempting;

  const hideMessageBox = useCallback(() => {
    setAuthState(AuthState.NEED_NEW_PASSWORD);
  }, [setAuthState]);

  useTimeout(showMessageBox, hideMessageBox, 2000);

  const handleCreatePassword = async () => {
    const result = testPassword(newPassword, confirmPassword);

    if (result !== 'success') {
      setMessageFirstLine(errorMessage[result]);
      setAuthState(AuthState.NEED_NEW_PASSWORD_INVALID);
    } else {
      console.log('user', user);
      setAttempting(true);
      const newUser = await createNewPassword(user, newPassword);
      console.log(newUser);
      if (newUser !== undefined) {
        setUser(newUser);
        setAuthState(AuthState.AUTHED);
      }

      setAttempting(false);
    }
  };

  const handleCancel = () => {
    setAuthState(AuthState.UNAUTHED);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={`${styles.banner} ${styles.bannerForgotPassword}`}>
          Make New Password
        </div>
        <div className={styles.instructions}>
          This is your first time logging in. Please create a strong new
          password.
        </div>
      </div>

      <PasswordPolicyGroup
        newPassword={newPassword}
        confirmPassword={confirmPassword}
      />

      <form onSubmit={disableSubmit}>
        <TextInput
          type='password'
          label='New Password'
          value={newPassword}
          setValue={setNewPassword}
        />

        <TextInput
          type='password'
          label='Confirm Password'
          value={confirmPassword}
          setValue={setConfirmPassword}
        />

        <MessageBox
          type={attempting ? 'attempting' : 'error'}
          show={showMessageBox}
          message={messageFirstLine}
        />

        <button
          type='submit'
          className={styles.button}
          onClick={handleCreatePassword}
        >
          Create password
        </button>
        <button
          type='button'
          className={`${styles.button} ${styles.buttonNegative}`}
          onClick={handleCancel}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

export default MakeNewPassword;

// eslint-disable-next-line import/no-cycle
import {
  sendCandidate,
  receiveCandidate,
  receiveSDP,
  sendSDP,
} from './signaler';

export interface Device {
  label: string;
  value: string;
}

export interface InboundReport {
  type: string;
  kind: string;
  framesPerSecond: string;
  frameWidth: string;
  frameHeight: string;
  decoderImplementation: string;
  timestamp: number;
  bytesReceived: number;
  packetsReceived: number;
  packetsLost: number;
}
export interface OutboundReport {
  type: string;
  kind: string;
  framesPerSecond: string;
  frameWidth: string;
  frameHeight: string;
  encoderImplementation: string;
  timestamp: number;
  bytesSent: number;
}

export interface InboundStats {
  fps: string;
  resolution: string;
  codec: string;
  bitrate: number | undefined;
  packetLossRate: string | undefined;
  totalPacketsLost: number;
}

export interface OutboundStats {
  fps: string;
  resolution: string;
  codec: string;
  bitrate: number | undefined;
}

export const getDeviceList = async (
  type: 'videoinput' | 'audioinput' | 'audiooutput'
): Promise<Device[]> => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    throw new Error('enumerateDevices() not supported');
  }

  const devices = await navigator.mediaDevices.enumerateDevices();

  const filteredDevices = devices
    .filter((device: MediaDeviceInfo) => device.kind === type)
    .filter(device => device.deviceId !== 'default')
    .map(device => ({
      value: device.deviceId,
      label: device.label,
    }));

  return filteredDevices;
};

const getConstraints = (
  isFront: boolean,
  selectedCamera?: string,
  selectedMicrophone?: string,
  selectedSpeaker?: string
): MediaStreamConstraints => {
  const audioList = [selectedMicrophone, selectedSpeaker].filter(
    selected => selected !== undefined
  ) as string[];

  const constraints = {
    video: {
      deviceId: selectedCamera,
      frameRate: {
        ideal: 60,
        min: 22,
      },
      aspectRatio: 1.777,
      facingMode: isFront ? 'user' : 'environment',
    },
    audio: audioList.length > 0 ? { deviceId: audioList } : true,
  };

  return constraints;
};

export const stopStream = (stream: MediaStream | undefined): void => {
  if (stream !== undefined) {
    stream.getTracks().forEach(track => track.stop());
  }
};

export const toggleMute = (stream: MediaStream) => {
  stream.getTracks().forEach(track => {
    // eslint-disable-next-line no-param-reassign
    track.enabled = !track.enabled;
  });
};

export const getLocalMedia = (
  isFront: boolean,
  selectedCamera?: string,
  selectedMicrophone?: string,
  selectedSpeaker?: string
): Promise<MediaStream> => {
  const constraints = getConstraints(
    isFront,
    selectedCamera,
    selectedMicrophone,
    selectedSpeaker
  );
  const mediaStream = navigator.mediaDevices.getUserMedia(constraints);
  return mediaStream;
};

export const createOfferSDP = async (
  peer: RTCPeerConnection,
  handler: (sdp: RTCSessionDescription) => void
) => {
  const offerSDP = await peer.createOffer();
  await peer.setLocalDescription(offerSDP);
  if (peer.localDescription !== null) handler(peer.localDescription);
};

export const parseInboundReport = (
  prevReport: InboundReport | undefined,
  report: InboundReport
): InboundStats => {
  const {
    framesPerSecond,
    frameWidth,
    frameHeight,
    decoderImplementation,
    timestamp,
    bytesReceived,
    packetsReceived,
    packetsLost,
  } = report;

  const packetLossRate =
    prevReport?.packetsLost !== undefined &&
    prevReport?.packetsReceived !== undefined
      ? `${(
          ((packetsLost - prevReport.packetsLost) /
            (packetsReceived - prevReport.packetsReceived)) *
          100
        ).toFixed(3)}%`
      : undefined;

  const bitrate =
    prevReport?.timestamp !== undefined &&
    prevReport?.bytesReceived !== undefined
      ? Math.round(
          ((bytesReceived - prevReport.bytesReceived) /
            ((timestamp - prevReport.timestamp) / 1000)) *
            0.008
        )
      : undefined;

  const stats = {
    fps: framesPerSecond,
    resolution: `${frameWidth}x${frameHeight}`,
    codec: decoderImplementation,
    packetLossRate,
    totalPacketsLost: packetsLost,
    bitrate,
  };

  return stats;
};

export const parseOutboundReport = (
  prevReport: OutboundReport | undefined,
  report: OutboundReport
): OutboundStats => {
  const {
    framesPerSecond,
    frameWidth,
    frameHeight,
    encoderImplementation,
    timestamp,
    bytesSent,
  } = report;

  const bitrate =
    prevReport?.timestamp !== undefined && prevReport?.bytesSent !== undefined
      ? Math.round(
          ((bytesSent - prevReport.bytesSent) /
            ((timestamp - prevReport.timestamp) / 1000)) *
            0.008
        )
      : undefined;

  const stats = {
    fps: framesPerSecond,
    resolution: `${frameWidth}x${frameHeight}`,
    codec: encoderImplementation,
    bitrate,
  };

  return stats;
};

// const collectStats = () => {
//   newPeer
//     .getStats(null)
//     .then(stats =>
//       stats.forEach(report => {
//         if (report.type === 'inbound-rtp' && report.kind === 'video') {
//           const {
//             framesPerSecond,
//             frameWidth,
//             frameHeight,
//             decoderImplementation,
//             timestamp,
//             bytesReceived,
//             packetsReceived,
//             packetsLost,
//           } = report;

//           const prevTimestamp = prevIncomingStats.current?.timestamp;
//           const prevBytesReceived =
//             prevIncomingStats.current?.bytesReceived;
//           const prevPacketsReceived =
//             prevIncomingStats.current?.packetsReceived;
//           const prevPacketsLost =
//             prevIncomingStats.current?.packetsLost;

//           const packetLossRate =
//             prevPacketsLost !== undefined &&
//             prevPacketsReceived !== undefined
//               ? `${(
//                   ((packetsLost - prevPacketsLost) /
//                     (packetsReceived - prevPacketsReceived)) *
//                   100
//                 ).toFixed(3)}%`
//               : undefined;

//           const bitrate =
//             prevTimestamp !== undefined &&
//             prevBytesReceived !== undefined
//               ? `${Math.round(
//                   ((bytesReceived - prevBytesReceived) /
//                     ((timestamp - prevTimestamp) / 1000)) *
//                     0.008
//                 )} Kb/s`
//               : undefined;

//           const newStats = {
//             fps: framesPerSecond,
//             resolution: `${frameWidth}x${frameHeight}`,
//             codec: decoderImplementation,
//             packetLossRate,
//             totalPacketsLost: packetsLost,
//             bitrate,
//           };
//           dispatch(setIncomingStats(newStats));
//           prevIncomingStats.current = {
//             timestamp,
//             bytesReceived,
//             packetsReceived,
//             packetsLost,
//           };
//         }

//         if (report.type === 'outbound-rtp' && report.kind === 'video') {
//           const {
//             framesPerSecond,
//             frameWidth,
//             frameHeight,
//             encoderImplementation,
//             timestamp,
//             bytesSent,
//           } = report;

//           const prevTimestamp = prevOutgoingStats.current?.timestamp;
//           const prevBytesSent = prevOutgoingStats.current?.bytesSent;

//           const bitrate =
//             prevTimestamp !== undefined && prevBytesSent !== undefined
//               ? `${Math.round(
//                   ((bytesSent - prevBytesSent) /
//                     ((timestamp - prevTimestamp) / 1000)) *
//                     0.008
//                 )} Kb/s`
//               : undefined;

//           const newStats = {
//             fps: framesPerSecond,
//             resolution: `${frameWidth}x${frameHeight}`,
//             codec: encoderImplementation,
//             bitrate,
//           };
//           dispatch(setOutgoingStats(newStats));

//           prevOutgoingStats.current = {
//             timestamp,
//             bytesSent,
//           };
//         }
//       })
//     )
//     .catch(e => console.error(e));
// };

export const createPeer = (
  socket: SocketIOClient.Socket,
  iceConfig: RTCConfiguration,
  roomName: string,
  localId: string,
  peerId: string,
  setRemoteStream: (stream: MediaStream) => void
) => {
  const peer = new RTCPeerConnection(iceConfig);

  peer.ontrack = (event: RTCTrackEvent): void => {
    console.log('ontrack event', event);
    const { streams } = event;
    if (streams !== undefined && streams.length > 0) {
      setRemoteStream(streams[0]);
    }
  };

  peer.onnegotiationneeded = async () => {
    try {
      console.log('negotiation needed!!');
      const offerSDP = await peer.createOffer();
      await peer.setLocalDescription(offerSDP);
      const { localDescription } = peer;
      if (localDescription !== null) {
        console.log('sending sdp');
        sendSDP(socket, localDescription, peerId);
      }
    } catch (err) {
      console.error(err);
    }
  };

  peer.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
    const { candidate } = event;
    sendCandidate(socket, candidate, roomName, peerId);
    console.log('candidate sent', candidate);
  };

  peer.onicecandidateerror = (ev: RTCPeerConnectionIceErrorEvent) => {
    console.error(ev);
  };

  receiveSDP(socket, async sdp => {
    try {
      await peer.setRemoteDescription(sdp);
      console.log('sdp received');
    } catch (e) {
      console.error(e);
    }
  });

  receiveCandidate(socket, candidate => {
    if (candidate) {
      peer.addIceCandidate(candidate);
      console.log('candidate received', candidate);
    }
  });

  return peer;
};

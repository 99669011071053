import React from 'react';

import { getMiniMap } from '../../utils/location';
import { CSSTransition } from 'react-transition-group';

import { useSelector } from 'react-redux';

import styles from './styles.module.css';
import { RootState } from '../../store';
import { VideoState } from '../../store/interface';
import fade from '../../common/transitions/fade.module.css';

import usePatronAddress from '../../hooks/usePatronAddress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

// const containerStyle = {
//   width: '400px',
//   height: '400px',
// };

// // https://maps.googleapis.com/maps/api/staticmap?center=Berkeley,CA&zoom=14&size=400x400&key=AIzaSyDWRSyBd-yEkrKUQ0JJ6q7rn0l62y4W3Zg
// https://maps.googleapis.com/maps/api/staticmap?center=40.714728,-73.998672&zoom=12&size=400x400&key=AIzaSyDWRSyBd-yEkrKUQ0JJ6q7rn0l62y4W3Zg

const CallerID = () => {
  const { patronLocation, patronUserInfo: userInfo, videoState } = useSelector(
    (state: RootState) => state.interface
  );

  const address = usePatronAddress(patronLocation);

  const show = videoState === VideoState.IN_CALL && userInfo !== undefined;

  const minimap = patronLocation && getMiniMap(patronLocation);

  return (
    <CSSTransition
      in={show}
      classNames={fade}
      timeout={300}
      mountOnEnter
      unmountOnExit
    >
      <div className={styles.container}>
        <div className={styles.patronInfo}>
          <div className={styles.patronName}>
            {userInfo && `${userInfo.firstName} ${userInfo.lastName}`}
            <button className={styles.copyIDButton}>
              <FontAwesomeIcon className={styles.copyIcon} icon={faCopy} />
              ID
            </button>
          </div>
          <div className={styles.patronEmail}>{userInfo && userInfo.email}</div>
          <div className={styles.patronLocation}>
            <div className={styles.street}>{address?.address}</div>
            {`${address?.city}, ${address?.state}`}
          </div>
        </div>
        <div className={styles.minimap}>
          {patronLocation && (
            <img className={styles.minimapImg} alt='' src={minimap} />
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

export default CallerID;

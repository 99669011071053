import { useEffect, useState, useRef, useContext } from 'react';

import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { clearInstanceId } from '../utils/instanceId';
import { RootState, useAppDispatch } from '../store';
import {
  VideoState,
  setVideoState,
  setNumTerps,
  setPatronLocation,
  setPatronUserInfo,
} from '../store/interface';
import { setLocalId } from '../store/webrtc';

import cognitoContext, { AuthState } from '../context/cognito';

import { getTokens, getUserInfo, logout } from '../utils/cognito';

import {
  connect,
  receiveCreateOffer,
  SocketId,
  receiveInvite,
  sendIVR,
  sendDismiss,
  startHeartbeat,
  stopHeartbeat,
  receivePulsation,
} from '../utils/signaler';

export interface IncomingPeer {
  iceConfig: RTCConfiguration;
  peerId: string;
  roomName: string;
  socket: SocketIOClient.Socket;
}

const useIncomingPeer = () => {
  const [incomingPeer, setIncomingPeer] = useState<IncomingPeer>();

  const peerId = useRef<string>('');

  const dispatch = useAppDispatch();

  const { isAuthed, setUser, setAuthState } = useContext(cognitoContext);

  const close = (
    socket: SocketIOClient.Socket | undefined,
    heartbeat: NodeJS.Timeout | undefined
  ) => {
    setIncomingPeer(undefined);
    if (socket !== undefined) {
      socket.disconnect();
    }

    if (heartbeat !== undefined) {
      stopHeartbeat(heartbeat);
    }
  };

  const signalerUrl = useSelector(
    (state: RootState) => state.webrtc.signalerUrl
  );

  const videoState = useSelector(
    (state: RootState) => state.interface.videoState
  );

  const roomName = useRef<string>(uuid());
  const socket = useRef<SocketIOClient.Socket>();
  const heartbeat = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (socket.current !== undefined) {
      if (videoState === VideoState.IVR_ACCEPTED) {
        sendIVR(socket.current, roomName.current, 'accept', peerId.current);
      }

      if (
        videoState === VideoState.IVR_DECLINED ||
        videoState === VideoState.IVR_MISSED
      ) {
        sendIVR(
          socket.current,
          roomName.current,
          videoState === VideoState.IVR_DECLINED ? 'reject' : 'miss',
          peerId.current
        );

        dispatch(setVideoState(VideoState.ON_STANDBY));
      }

      if (videoState === VideoState.TERP_HUNG_UP) {
        sendDismiss(socket.current, peerId.current, roomName.current);
      }
    }
  }, [dispatch, videoState]);

  useEffect(() => {
    const createNewSocket = async () => {
      close(socket.current, heartbeat.current);

      if (isAuthed) {
        const tokens = await getTokens();
        const { id } = await getUserInfo();

        roomName.current = id;

        if (tokens !== undefined) {
          const newSocket = connect(
            signalerUrl,
            roomName.current,
            tokens.access.getJwtToken(),
            dispatch
          );

          if (newSocket !== undefined) {
            receivePulsation(newSocket, interval => {
              heartbeat.current = startHeartbeat(newSocket, interval);
            });

            newSocket.on('id', (data: SocketId) => {
              console.log('id received', data);
              dispatch(setVideoState(VideoState.ON_STANDBY));
              dispatch(setLocalId(data.localId));
            });

            newSocket.on('dismiss', async () => {
              console.log('you are dismissed!!!');
              const result = await logout();
              if (result) {
                setUser(undefined);
                clearInstanceId();
                setAuthState(AuthState.UNAUTHED);
              }
            });

            receiveInvite(
              newSocket,
              (pid, avail, occupied, location, userInfo) => {
                peerId.current = pid;
                console.log('peer id', pid);
                dispatch(setPatronUserInfo(userInfo));
                dispatch(setPatronLocation(location));
                dispatch(setNumTerps({ occupied, avail }));
                dispatch(setVideoState(VideoState.IS_RINGING));
              }
            );

            receiveCreateOffer(newSocket, (iceConfig, _attributes) => {
              dispatch(setVideoState(VideoState.IS_PICKING_UP));

              setIncomingPeer({
                iceConfig,
                roomName: roomName.current,
                peerId: peerId.current ? peerId.current : '',
                socket: newSocket,
              });
            });

            socket.current = newSocket;
          }
        }
      }
    };

    createNewSocket();

    return () => {
      close(socket.current, heartbeat.current);
    };
  }, [isAuthed, dispatch, signalerUrl, setUser, setAuthState]);

  return incomingPeer;
};

export default useIncomingPeer;

import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
  },
});

export const login = async (
  username: string,
  password: string
): Promise<any> => {
  try {
    console.log('attempting login');
    const newUser = await Auth.signIn(username, password);
    return newUser;
  } catch (e) {
    console.error(e);

    if (e.message === 'Incorrect username or password.') {
      return 'authError';
    }
    if (e.message === 'Password attempts exceeded') {
      return 'limitExceeded';
    }

    if (e.message === 'Password reset required for the user') {
      return 'resetRequired';
    }
  }
  return 'unknown';
};

export interface UserInfo {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export const getAuthedUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const forgotPasswordEmail = async (username: string) => {
  try {
    const result = await Auth.forgotPassword(username);
    console.log(result);
    return result;
  } catch (e) {
    console.error(e);
    if (e.code === 'LimitExceededException') {
      return 'limitExceeded';
    }
  }
  return undefined;
};

export const forgotPasswordCode = async (
  username: string,
  code: string,
  newPassword: string
) => {
  try {
    const result = await Auth.forgotPasswordSubmit(username, code, newPassword);
    console.log(result);
    return result;
  } catch (e) {
    console.error(e);
    return 'codeMismatch';
  }
};

export const createNewPassword = async (user: any, newPassword: string) => {
  try {
    const newUser = await Auth.completeNewPassword(user, newPassword);
    return newUser;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const getUserInfo = async (): Promise<UserInfo> => {
  try {
    const { attributes } = await Auth.currentUserInfo();

    const info: UserInfo = {
      id: attributes.sub,
      email: attributes.email,
      firstName: attributes.name,
      lastName: attributes.family_Name,
    };

    return info;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};

export const getTokens = async () => {
  try {
    const session = await Auth.currentSession();
    const tokens = {
      id: session.getIdToken(),
      access: session.getAccessToken(),
    };
    return tokens;
  } catch (error) {
    console.log(error);
  }

  return undefined;
};

export const logout = async () => {
  try {
    await Auth.signOut();
    return true;
  } catch (error) {
    console.error(error);
  }
  return false;
};

import React, { useContext } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import adapter from 'webrtc-adapter';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Login from '../Login';
import ForgotPasswordEmail from '../Login/ForgotPasswordEmail';
import ForgotPasswordCode from '../Login/ForgotPasswordCode';
import MakeNewPassword from '../Login/MakeNewPassword';
import LocalVideo from '../LocalVideo';
import RemoteVideo from '../RemoteVideo';
import Loading from '../Loading';
import IVR from '../IVR';
import ConnectionError from '../ConnectionError';
import ConnectionOperational from '../ConnectionOperational';
import ActionBar from '../ActionBar';
import UserSettings from '../UserSettings';
import CallerID from '../CallerID';
import Stats from '../Stats';
import useLocalVideo from '../../hooks/useLocalVideo';
import useIncomingPeer from '../../hooks/useIncomingPeer';
import useRemoteVideo from '../../hooks/useRemoteVideo';
import useCheckAuth from '../../hooks/useCheckAuth';
import useCloseConfirmation from '../../hooks/useCloseConfirmation';

import slowFade from '../../common/transitions/slowFade.module.css';
import styles from './styles.module.css';
import cognitoContext, { AuthState } from '../../context/cognito';

const App = () => {
  const { authState } = useContext(cognitoContext);
  useCheckAuth();
  useCloseConfirmation();

  const localVideoStream = useLocalVideo();
  const incomingPeer = useIncomingPeer();
  const remoteVideoStream = useRemoteVideo(localVideoStream, incomingPeer);

  return (
    <TransitionGroup>
      {(authState === AuthState.INIT && (
        <CSSTransition key='loading' classNames={slowFade} timeout={600}>
          <Loading />
        </CSSTransition>
      )) ||
        (authState === AuthState.AUTHED && (
          <CSSTransition key='authed' classNames={slowFade} timeout={600}>
            <div className={styles.container}>
              <ConnectionError />
              <Stats />
              <ConnectionOperational />
              <UserSettings />
              <CallerID />
              <IVR />
              {localVideoStream !== undefined && (
                <LocalVideo localVideoStream={localVideoStream} />
              )}
              {remoteVideoStream !== undefined && (
                <RemoteVideo remoteVideoStream={remoteVideoStream} />
              )}
              <ActionBar />
            </div>
          </CSSTransition>
        )) ||
        ((authState === AuthState.FORGOT_PASSWORD_EMAIL_SENT ||
          authState === AuthState.FORGOT_PASSWORD_INVALID_CODE ||
          authState === AuthState.FORGOT_PASSWORD_INVALID_PASSWORD) && (
          <CSSTransition
            key='forgotPasswordCode'
            classNames={slowFade}
            timeout={600}
          >
            <ForgotPasswordCode />
          </CSSTransition>
        )) ||
        ((authState === AuthState.FORGOT_PASSWORD ||
          authState === AuthState.FORGOT_PASSWORD_INVALID_EMAIL) && (
          <CSSTransition
            key='forgotPasswordEmail'
            classNames={slowFade}
            timeout={600}
          >
            <ForgotPasswordEmail />
          </CSSTransition>
        )) ||
        ((authState === AuthState.NEED_NEW_PASSWORD ||
          authState === AuthState.NEED_NEW_PASSWORD_INVALID) && (
          <CSSTransition
            key='needNewPassword'
            classNames={slowFade}
            timeout={600}
          >
            <MakeNewPassword />
          </CSSTransition>
        )) || (
          <CSSTransition key='unauthed' classNames={slowFade} timeout={600}>
            <Login />
          </CSSTransition>
        )}
    </TransitionGroup>
  );
};

export default App;

import { v4 } from 'uuid';

const storage = window.sessionStorage;
const key = 'instanceId';

export const getInstanceId = () => {
  const instanceId = storage.getItem(key);

  if (!instanceId) {
    const newId = v4();
    storage.setItem(key, newId);
    return newId;
  }

  return instanceId;
};

export const clearInstanceId = () => {
  console.log('clearing instance id');
  storage.removeItem(key);
};

/* eslint-disable no-param-reassign */

import {
  createAction,
  createReducer,
  createAsyncThunk,
} from '@reduxjs/toolkit';

// eslint-disable-next-line import/no-cycle
import { getDeviceList, InboundStats, OutboundStats } from '../utils/webrtc';

export interface Device {
  label: string;
  value: string;
}

interface WebrtcState {
  signalerUrl: string;
  localId: string;
  cameraList: Device[];
  microphoneList: Device[];
  speakerList: Device[];
  selectedCamera?: Device;
  selectedMicrophone?: Device;
  selectedSpeaker?: Device;
  inboundStats?: InboundStats;
  outboundStats?: OutboundStats;
}

// stats
// Frames per second
// resolution
// codec
// bitrate
// packet loss rate

export const updateDeviceList = createAsyncThunk(
  '/app/webrtc/UPDATE_DEVICE_LIST',
  () => {
    return Promise.all([
      getDeviceList('videoinput'),
      getDeviceList('audioinput'),
      getDeviceList('audiooutput'),
    ]);
  }
);

export const setLocalId = createAction<string>('app/webrtc/SET_LOCAL_ID');

export const setSignalerUrl = createAction<string>(
  'app/webrtc/SET_SIGNALER_URL'
);

export const setSelectedCamera = createAction<Device>(
  'app/webrtc/SET_SELECTED_CAMERA'
);

export const setSelectedMicrophone = createAction<Device>(
  'app/webrtc/SET_SELECTED_MICROPHONE'
);

export const setSelectedSpeaker = createAction<Device>(
  'app/webrtc/SET_SELECTED_SPEADKER'
);

export const setCameraList = createAction<Device[]>(
  'app/webrtc/SET_CAMERA_LIST'
);

export const setMicrophoneList = createAction<Device[]>(
  'app/webrtc/SET_MICROPHONE_LIST'
);
export const setSpeakerList = createAction<Device[]>(
  'app/webrtc/SET_SPEAKER_LIST'
);

export const setOutboundStats = createAction<OutboundStats | undefined>(
  'app/webrtc/SET_OUTBOUND_STATS'
);

export const setInboundStats = createAction<InboundStats | undefined>(
  'app/webrtc/SET_INBOUND_STATS'
);

const initialState: WebrtcState = {
  signalerUrl: process.env.REACT_APP_SIGNALER_URL || '',
  localId: '',
  cameraList: [],
  microphoneList: [],
  speakerList: [],
  outboundStats: undefined,
  inboundStats: undefined,
};

export default createReducer(initialState, {
  [setSignalerUrl.type]: (state, action) => {
    state.signalerUrl = action.payload;
  },
  [setLocalId.type]: (state, action) => {
    state.localId = action.payload;
  },
  [setSelectedMicrophone.type]: (state, action) => {
    state.selectedMicrophone = action.payload;
  },
  [setSelectedSpeaker.type]: (state, action) => {
    state.selectedSpeaker = action.payload;
  },
  [setOutboundStats.type]: (state, action) => {
    state.outboundStats = action.payload;
  },
  [setInboundStats.type]: (state, action) => {
    state.inboundStats = action.payload;
  },
  [setSelectedCamera.type]: (state, action) => {
    state.selectedCamera = action.payload;
  },
  [setCameraList.type]: (state, action) => {
    state.cameraList = action.payload;
  },
  [setMicrophoneList.type]: (state, action) => {
    state.microphoneList = action.payload;
  },
  [setSpeakerList.type]: (state, action) => {
    state.speakerList = action.payload;
  },
  [updateDeviceList.fulfilled.type]: (state, action) => {
    const [cameraList, microphoneList, speakerList] = action.payload;
    state.cameraList = cameraList;
    state.microphoneList = microphoneList;
    state.speakerList = speakerList;
  },
});

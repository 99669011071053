import React, { useCallback, useEffect } from 'react';

import useSound from 'use-sound';

import { CSSTransition } from 'react-transition-group';

import { useSelector, useDispatch } from 'react-redux';
import ringing from '../../assets/cartoon_telephone.mp3';
import Buttons from './Buttons';
import Countdown from './Countdown';
import CallerID from './CallerID';
import OtherInterpreters from './OtherInterpreters';
import { RootState } from '../../store';
import { VideoState, setVideoState } from '../../store/interface';

import fade from '../../common/transitions/fade.module.css';
import styles from './styles.module.css';

const IVR = (): JSX.Element => {
  const dispatch = useDispatch();

  const [play, { isPlaying, stop }] = useSound(ringing, {
    interrupt: true,
    volume: 1,
  });
  const { videoState } = useSelector((state: RootState) => state.interface);

  const show = videoState === VideoState.IS_RINGING;

  useEffect(() => {
    if (show && !isPlaying) {
      console.log('hey! ringing start playing now!!!!');
      play();
    } else if (!show) {
      console.log('ringing stopped');
      stop();
    }
  }, [isPlaying, play, show, stop]);

  const decline = () => {
    dispatch(setVideoState(VideoState.IVR_DECLINED));
  };

  const miss = () => {
    dispatch(setVideoState(VideoState.IVR_MISSED));
  };

  const accept = useCallback(() => {
    dispatch(setVideoState(VideoState.IVR_ACCEPTED));
  }, [dispatch]);

  return (
    <CSSTransition
      in={show}
      classNames={fade}
      unmountOnExit
      mountOnEnter
      timeout={300}
    >
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <CallerID />
          <div className={styles.boxContainer}>
            <div className={styles.contentContainer}>
              <Countdown active={show} initialCount={15} timeout={miss} />
              <Buttons accept={accept} decline={decline} />
            </div>
          </div>
          <OtherInterpreters />
        </div>
      </div>
    </CSSTransition>
  );
};

export default IVR;

import React from 'react';

import PasswordPolicy from './PasswordPolicy';

import {
  lengthTest,
  hasLowerCase,
  hasUpperCase,
  hasNumber,
} from '../../utils/password';

import styles from './passwordPolicyGroup.module.css';

interface Props {
  newPassword: string;
  confirmPassword: string;
}

const PasswordPolicyGroup = (props: Props) => {
  const { newPassword, confirmPassword } = props;

  const lengthRuleSatisfied = lengthTest(newPassword);

  const caseRuleSatisfied =
    hasLowerCase(newPassword) && hasUpperCase(newPassword);

  const numberRuleSatisfied = hasNumber(newPassword);

  const matchRuleSatisfied =
    newPassword === confirmPassword && newPassword.length > 0;

  return (
    <div className={styles.container}>
      <PasswordPolicy
        satisfied={lengthRuleSatisfied}
        label='Password must be at least 8 characters long.'
      />
      <PasswordPolicy
        satisfied={caseRuleSatisfied}
        label='Password must has at least one uppercase and one lowercase letter.'
      />

      <PasswordPolicy
        satisfied={numberRuleSatisfied}
        label='Password must has at least one number.'
      />

      <PasswordPolicy
        satisfied={matchRuleSatisfied}
        label='The passwords must match.'
      />
    </div>
  );
};

export default PasswordPolicyGroup;

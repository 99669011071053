import React from 'react';

import { CSSTransition } from 'react-transition-group';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import fade from '../../common/transitions/fade.module.css';
import styles from './styles.module.css';
import { VideoState } from '../../store/interface';

const Stats = () => {
  const { videoState, showStatistics } = useSelector(
    (state: RootState) => state.interface
  );
  const { inboundStats, outboundStats } = useSelector(
    (state: RootState) => state.webrtc
  );

  const show =
    showStatistics &&
    videoState === VideoState.IN_CALL &&
    inboundStats !== undefined &&
    outboundStats !== undefined;

  return (
    <CSSTransition
      in={show}
      timeout={300}
      classNames={fade}
      mountOnEnter
      unmountOnExit
    >
      <div className={styles.container}>
        <div className={styles.statsCollection}>
          <div className={styles.banner}>Outgoing Stream Statistics</div>
          <div className={styles.stat}>
            <div className={styles.label}>Codec</div>
            <div className={styles.value}>{outboundStats?.codec}</div>
          </div>

          <div className={styles.stat}>
            <div className={styles.label}>Frames per Second</div>
            <div className={styles.value}>{outboundStats?.fps}</div>
          </div>
          <div className={styles.stat}>
            <div className={styles.label}>Resolution</div>
            <div className={styles.value}>{outboundStats?.resolution}</div>
          </div>
          <div className={styles.stat}>
            <div className={styles.label}>Bitrate</div>
            <div className={styles.value}>{outboundStats?.bitrate} Kb/s</div>
          </div>
        </div>

        <div className={styles.statsCollection}>
          <div className={styles.banner}>Incoming Stream Statistics</div>
          <div className={styles.stat}>
            <div className={styles.label}>Codec</div>
            <div className={styles.value}>{inboundStats?.codec}</div>
          </div>

          <div className={styles.stat}>
            <div className={styles.label}>Frames per Second</div>
            <div className={styles.value}>{inboundStats?.fps}</div>
          </div>
          <div className={styles.stat}>
            <div className={styles.label}>Resolution</div>
            <div className={styles.value}>{inboundStats?.resolution}</div>
          </div>
          <div className={styles.stat}>
            <div className={styles.label}>Bitrate</div>
            <div className={styles.value}>{inboundStats?.bitrate} Kb/s</div>
          </div>
          <div className={styles.stat}>
            <div className={styles.label}>Packet Loss Rate</div>
            <div className={styles.value}>{inboundStats?.packetLossRate}</div>
          </div>
          <div className={styles.stat}>
            <div className={styles.label}>Total Packets Lost</div>
            <div className={styles.value}>{inboundStats?.totalPacketsLost}</div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Stats;

import React from 'react';

import useCountdown from '../../hooks/useCountdown';
import styles from './styles.module.css';

interface Props {
  initialCount: number; // in seconds
  timeout: () => void;
  active: boolean;
}

const Countdown = (props: Props): JSX.Element => {
  const { initialCount, timeout, active } = props;

  const count = useCountdown(active, initialCount, timeout);

  const countdownStyle = `${styles.countdown} ${styles.countdownAnimate}`;

  return (
    <div className={styles.countdownContainer}>
      <div className={countdownStyle}>
        <div className={styles.countdownText}>{count}</div>
      </div>
    </div>
  );
};

export default Countdown;

import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../../store';
import styles from './styles.module.css';

import Video from '../Video';

interface Props {
  remoteVideoStream: MediaStream;
}

const RemoteVideo = (props: Props) => {
  const { remoteVideoStream } = props;
  const { remoteMute } = useSelector((state: RootState) => state.interface);

  return (
    <div className={styles.container}>
      {remoteVideoStream && !remoteMute && (
        <Video muted={false} srcObject={remoteVideoStream} />
      )}
      {remoteMute && (
        <div className={styles.mute}>
          <FontAwesomeIcon icon={faVideoSlash} className={styles.muteIcon} />
          muted
        </div>
      )}
    </div>
  );
};

export default RemoteVideo;

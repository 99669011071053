import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faKey,
  faUser,
  faEnvelopeOpenText,
  faAt,
  faLink,
} from '@fortawesome/free-solid-svg-icons';

import styles from './input.module.css';

interface Props {
  label: string;
  value: string;
  type?: 'user' | 'password' | 'code' | 'email' | 'url';
  setValue: (value: string) => void;
}

const TextInput = (props: Props) => {
  const { label, value, setValue, type = 'text' } = props;

  const icon =
    (type === 'user' && faUser) ||
    (type === 'email' && faAt) ||
    (type === 'password' && faKey) ||
    (type === 'url' && faLink) ||
    (type === 'code' && faEnvelopeOpenText);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
  };

  return (
    <div className={styles.container}>
      <label className={styles.label}>
        {icon && <FontAwesomeIcon icon={icon} className={styles.icon} />}
        {label}
        <div className={styles.inputContainer}>
          <input
            type={type === 'password' ? 'password' : 'text'}
            value={value}
            className={styles.input}
            onChange={handleChange}
          />
        </div>
      </label>
    </div>
  );
};

export default TextInput;

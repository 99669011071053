import React from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import styles from './styles.module.css';

const OtherInterpreters = () => {
  const { terps } = useSelector((state: RootState) => state.interface);

  return (
    <div className={styles.boxContainer}>
      <div className={styles.otherTerpsHeader}>Other Interpreters</div>
      <div className={styles.numTerps}>
        <div className={styles.numTerpsBoxContainer}>
          <div className={styles.numTerpsBox}>{terps.avail} available</div>
        </div>
        <div className={styles.numTerpsBoxContainer}>
          <div className={styles.numTerpsBox}>{terps.occupied - 1} busy</div>
        </div>
      </div>
    </div>
  );
};

export default OtherInterpreters;

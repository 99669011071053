import { createAction, createReducer } from '@reduxjs/toolkit';
import { UserInfo } from '../utils/cognito';
import { LngLat } from '../utils/location';

export enum SignalerState {
  CONNECTING = 'CONNECTING',
  CONNECTED = 'CONNECTED',
  CONNECTION_ERROR = 'CONNECTION_ERROR',
  DISCONNECTED = 'DISCONNECTED',
  TOKEN_AUTH_FAILED = 'TOKEN_AUTH_FAILED',
}

export enum VideoState {
  NOT_IN_CALL = 'NOT_IN_CALL',
  ON_STANDBY = 'ON_STANDBY',
  IS_RINGING = 'IS_RINGING',
  IVR_ACCEPTED = 'IVR_ACCEPTED',
  IVR_DECLINED = 'IVR_DECLINED',
  IVR_MISSED = 'IVR_MISSED',
  IS_PICKING_UP = 'IS_PICKING_UP',
  IN_CALL = 'IN_CALL',
  IN_CALL_MUTED = 'IN_CALL_MUTED',
  PATRON_HUNG_UP = 'PATRON_HUNG_UP',
  TERP_HUNG_UP = 'TERP_HUNG_UP',
  ERROR = 'ERROR',
}

export enum LoginState {
  INIT = 'INIT',
  ATTEMPTING = 'ATTEMPTING',
  LOGIN_ERROR = 'LOGIN_ERROR',
  SUCCESS = 'SUCCESS',
}

export interface Terps {
  avail: number;
  occupied: number;
}

interface InterfaceState {
  videoState: VideoState;
  loginState: LoginState;
  signalerState: SignalerState;
  showStatistics: boolean;
  terps: Terps;
  localMute: boolean;
  remoteMute: boolean;
  patronLocation: LngLat | undefined;
  patronUserInfo: UserInfo | undefined;
}

export const setNumTerps = createAction<Terps>('app/interface/SET_NUM_TERPS');

export const setSignalerState = createAction<SignalerState>(
  'app/interface/SET_SIGNALER_STATE'
);

export const setShowStatistics = createAction<boolean>(
  'app/interface/SET_SHOW_STATISTICS'
);

export const setVideoState = createAction<VideoState>(
  'app/interface/SET_VIDEO_STATE'
);

export const setLoginState = createAction<LoginState>(
  'app/interface/SET_LOGIN_STATE'
);

export const setPatronLocation = createAction<LngLat | undefined>(
  'app/interface/SET_PATRON_LOCATION'
);

export const setPatronUserInfo = createAction<UserInfo | undefined>(
  'app/interface/SET_PATRON_USER_INFO'
);

export const setReconnectAttempt = createAction<number>(
  'app/interface/SET_RECONNECT_ATTEMPT'
);

export const toggleLocalMute = createAction('app/interface/TOGGLE_LOCAL_MUTE');

export const setLocalMute = createAction<boolean>(
  'app/interface/SET_LOCAL_MUTE'
);

export const setRemoteMute = createAction<boolean>(
  'app/interface/SET_REMOTE_MUTE'
);

const defaultState: InterfaceState = {
  videoState: VideoState.NOT_IN_CALL,
  loginState: LoginState.INIT,
  signalerState: SignalerState.CONNECTING,
  showStatistics: false,
  terps: {
    avail: 0,
    occupied: 0,
  },
  localMute: false,
  remoteMute: false,
  patronLocation: undefined,
  patronUserInfo: undefined,
};

export default createReducer(defaultState, {
  [setVideoState.type]: (state, action) => {
    // eslint-disable-next-line no-param-reassign
    state.videoState = action.payload;
  },
  [setShowStatistics.type]: (state, action) => {
    // eslint-disable-next-line no-param-reassign
    state.showStatistics = action.payload;
  },
  [setLoginState.type]: (state, action) => {
    // eslint-disable-next-line no-param-reassign
    state.loginState = action.payload;
  },
  [setSignalerState.type]: (state, action) => {
    // eslint-disable-next-line no-param-reassign
    state.signalerState = action.payload;
  },
  [setNumTerps.type]: (state, action) => {
    // eslint-disable-next-line no-param-reassign
    state.terps = action.payload;
  },
  [setPatronLocation.type]: (state, action) => {
    // eslint-disable-next-line no-param-reassign
    state.patronLocation = action.payload;
  },
  [setPatronUserInfo.type]: (state, action) => {
    // eslint-disable-next-line no-param-reassign
    state.patronUserInfo = action.payload;
  },
  [toggleLocalMute.type]: state => {
    // eslint-disable-next-line no-param-reassign
    state.localMute = !state.localMute;
  },
  [setLocalMute.type]: (state, action) => {
    // eslint-disable-next-line no-param-reassign
    state.localMute = action.payload;
  },
  [setRemoteMute.type]: (state, action) => {
    // eslint-disable-next-line no-param-reassign
    state.remoteMute = action.payload;
  },
});

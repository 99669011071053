import { useEffect, useRef } from 'react';

const useTimeout = (trigger: boolean, callback: () => void, delay: number) => {
  const timeoutFunc = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const cancelTimeout = () => {
      if (timeoutFunc.current !== undefined) {
        clearTimeout(timeoutFunc.current);
      }
    };

    if (trigger) {
      timeoutFunc.current = setTimeout(callback, delay);
    }

    return () => {
      cancelTimeout();
    };
  }, [callback, delay, trigger]);
};

export default useTimeout;

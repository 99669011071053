import React, { useState, useContext } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import Switch from 'react-switch';
import Select, { ValueType } from 'react-select';
import { CSSTransition } from 'react-transition-group';
import colors from '../../common/colors.module.css';
import { logout } from '../../utils/cognito';
import cognitoContext, { AuthState } from '../../context/cognito';
import TextInput from '../Login/TextInput';
import {
  setSelectedCamera,
  setSelectedMicrophone,
  setSelectedSpeaker,
  setSignalerUrl,
} from '../../store/webrtc';
import { Device } from '../../utils/webrtc';

import { clearInstanceId } from '../../utils/instanceId';

import fade from '../../common/transitions/fade.module.css';

import styles from './modal.module.css';
import { RootState } from '../../store';

import { setShowStatistics } from '../../store/interface';

interface Props {
  show: boolean;
}

const Modal = (props: Props) => {
  const { show } = props;
  const dispatch = useDispatch();

  const { showStatistics } = useSelector((state: RootState) => state.interface);

  const {
    selectedCamera,
    selectedMicrophone,
    selectedSpeaker,
    cameraList,
    speakerList,
    microphoneList,
    signalerUrl,
  } = useSelector((state: RootState) => state.webrtc);

  const [url, setUrl] = useState<string>(signalerUrl);

  const { setAuthState, setUser } = useContext(cognitoContext);

  const handleLogout = async () => {
    const result = await logout();
    if (result) {
      setUser(undefined);
      clearInstanceId();
      setAuthState(AuthState.UNAUTHED);
    }
  };

  return (
    <CSSTransition
      in={show}
      mountOnEnter
      unmountOnExit
      timeout={300}
      classNames={fade}
    >
      <div className={styles.container}>
        <div className={styles.modal}>
          <button
            type='button'
            className={styles.button}
            onClick={handleLogout}
          >
            <div className={styles.buttonText}>
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className={styles.buttonIcon}
              />
              Logout
            </div>
          </button>

          <div className={styles.deviceSelectLabel}>Selected Camera</div>
          <Select
            options={cameraList}
            defaultValue={
              selectedCamera !== undefined ? selectedCamera : cameraList[0]
            }
            onChange={(e: ValueType<Device, false>) =>
              dispatch(setSelectedCamera(e as Device))
            }
          />
          <div className={styles.deviceSelectLabel}>Selected Microphone</div>
          <Select
            options={microphoneList}
            defaultValue={
              selectedMicrophone !== undefined
                ? selectedMicrophone
                : microphoneList[0]
            }
            onChange={(e: ValueType<Device, false>) => {
              dispatch(setSelectedMicrophone(e as Device));
            }}
          />
          <div className={styles.deviceSelectLabel}>Selected Speaker</div>
          <Select
            options={speakerList}
            defaultValue={
              selectedSpeaker !== undefined ? selectedSpeaker : speakerList[0]
            }
            onChange={(e: ValueType<Device, false>) => {
              dispatch(setSelectedSpeaker(e as Device));
            }}
          />
          {process.env.REACT_APP_ENV === 'dev' && (
            <>
              <TextInput
                label='Signaler URL'
                type='url'
                value={url}
                setValue={setUrl}
              />
              <button
                type='button'
                style={{ marginTop: 5, background: colors.sapphireBlue }}
                className={styles.button}
                onClick={() => {
                  dispatch(setSignalerUrl(url));
                }}
              >
                <div className={styles.buttonText}>Apply new URL</div>
              </button>
            </>
          )}
          <div className={styles.showStatistics}>
            <div className={styles.statisticsLabel}>
              Show statistics during call
            </div>
            <Switch
              onColor={colors.emeraldGreen}
              activeBoxShadow={`0 0 2px 3px ${colors.sapphireBlueLight}`}
              checked={showStatistics}
              onChange={(checked: boolean) => {
                dispatch(setShowStatistics(checked));
              }}
            />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Modal;

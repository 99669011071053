import React from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import usePatronAddress from '../../hooks/usePatronAddress';

import { getMiniMap } from '../../utils/location';

import styles from './styles.module.css';

const CallerID = () => {
  const { patronLocation, patronUserInfo: userInfo } = useSelector(
    (state: RootState) => state.interface
  );

  const url =
    patronLocation !== undefined ? getMiniMap(patronLocation) : undefined;

  const address = usePatronAddress(patronLocation);

  return (
    <div className={styles.boxContainer}>
      <div className={styles.patronInfo}>
        {userInfo && (
          <>
            <div
              className={styles.patronName}
            >{`${userInfo.firstName} ${userInfo.lastName}`}</div>
            <div className={styles.patronEmail}>{userInfo.email}</div>
            <div className={styles.patronLocation}>
              <div className={styles.street}>{address?.address}</div>
              {`${address?.city}, ${address?.state}`}
            </div>
          </>
        )}
      </div>
      <div className={styles.mapContainer}>
        <img className={styles.map} alt='location' src={url} />
      </div>
    </div>
  );
};

export default CallerID;

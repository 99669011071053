import { useState, useEffect } from 'react';
import { getAddress, LngLat, PatronAddress } from '../utils/location';

const usePatronAddress = (location?: LngLat) => {
  const [address, setAddress] = useState<PatronAddress>();

  useEffect(() => {
    if (location !== undefined) {
      getAddress(location).then(result => {
        console.log(result);
        setAddress(result);
      });
    }
  }, [location]);

  return address;
};

export default usePatronAddress;

import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import { CSSTransition } from 'react-transition-group';

import fade from '../../common/transitions/fade.module.css';
import styles from './styles.module.css';

export enum ButtonType {
  POSITIVE,
  NEGATIVE,
  NEUTRAL,
}

interface Button {
  name: string;
  type: ButtonType;
  handler: () => void;
}

interface Props {
  show: boolean;
  title: string;
  message: string;
  buttons: Button[];
}

const Dialog = (props: Props) => {
  const { show, title, message, buttons } = props;
  const dialogRoot = useRef(document.getElementById('dialog')).current;

  return dialogRoot
    ? ReactDOM.createPortal(
        <CSSTransition
          in={show}
          mountOnEnter
          unmountOnExit
          classNames={fade}
          timeout={300}
        >
          <div className={styles.container}>
            <div className={styles.dialogContainer}>
              <div className={styles.title}>{title}</div>
              <div className={styles.message}>{message}</div>
              <div className={styles.buttonsContainer}>
                {buttons.map((button: Button) => (
                  <button
                    type='button'
                    className={styles.button}
                    onClick={button.handler}
                  >
                    {button.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </CSSTransition>,
        dialogRoot
      )
    : null;
};

export default Dialog;

import React, { useState, useCallback, useContext } from 'react';
import cognitoContext, { AuthState } from '../../context/cognito';

import { isEmailValid } from '../../utils/email';

import useTimeout from '../../hooks/useTimeout';
import TextInput from './TextInput';
import MessageBox from './MessageBox';

import { forgotPasswordEmail } from '../../utils/cognito';

import styles from './styles.module.css';

const disableSubmit = (e: any) => {
  e.preventDefault();
};

const ForgotPasswordEmail = (): JSX.Element => {
  const { setAuthState, authState, setUser } = useContext(cognitoContext);

  const [attempting, setAttempting] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const showMessageBox =
    attempting || authState === AuthState.FORGOT_PASSWORD_INVALID_EMAIL;

  const handleCancel = () => {
    setAuthState(AuthState.UNAUTHED);
  };

  const closeMessageBox = useCallback(() => {
    setAuthState(AuthState.FORGOT_PASSWORD);
  }, [setAuthState]);

  useTimeout(showMessageBox, closeMessageBox, 2000);

  const messageFirstLine = 'E-mail is invalid. Please try again!';

  const handleSendEmail = async () => {
    if (isEmailValid(email)) {
      setAttempting(true);
      const result = await forgotPasswordEmail(email);
      setAttempting(false);
      if (result !== undefined) {
        setUser(email);
        setAuthState(AuthState.FORGOT_PASSWORD_EMAIL_SENT);
      }
    } else {
      setAuthState(AuthState.FORGOT_PASSWORD_INVALID_EMAIL);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={`${styles.banner} ${styles.bannerForgotPassword}`}>
          Forgot Password
        </div>
        <div className={styles.instructions}>
          You will be sent an e-mail with a verification code. You may use it to
          reset your password.
        </div>
      </div>

      <form onSubmit={disableSubmit}>
        <TextInput
          type='email'
          label='E-mail'
          value={email}
          setValue={setEmail}
        />

        <MessageBox
          type={attempting ? 'attempting' : 'error'}
          show={showMessageBox}
          message={messageFirstLine}
        />

        <button
          type='submit'
          className={styles.button}
          onClick={handleSendEmail}
        >
          Send E-mail
        </button>
        <button
          type='button'
          className={`${styles.button} ${styles.buttonNegative}`}
          onClick={handleCancel}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

export default ForgotPasswordEmail;

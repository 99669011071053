import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAltSlash } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch } from '../../store';

import { VideoState, setVideoState } from '../../store/interface';

import colors from '../../common/colors.module.css';
import styles from './styles.module.css';

const HangUpButton = () => {
  const dispatch = useAppDispatch();
  const hangUp = () => dispatch(setVideoState(VideoState.TERP_HUNG_UP));

  return (
    <div className={styles.buttonContainer}>
      <button
        className={`${styles.button} ${colors.buttonNegative}`}
        onClick={hangUp}
        onKeyPress={hangUp}
        type='button'
      >
        <FontAwesomeIcon className={styles.buttonIcon} icon={faUserAltSlash} />
        <div className={styles.buttonLabel}>Dismiss</div>
      </button>
    </div>
  );
};

export default HangUpButton;

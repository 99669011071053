import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons';

import { useSelector } from 'react-redux';
import { useAppDispatch, RootState } from '../../store';

import { toggleLocalMute } from '../../store/interface';

import colors from '../../common/colors.module.css';
import styles from './styles.module.css';

const MuteButton = () => {
  const { localMute } = useSelector((state: RootState) => state.interface);

  const dispatch = useAppDispatch();
  const toggleMute = () => dispatch(toggleLocalMute());

  return (
    <div className={styles.buttonContainer}>
      <button
        className={`${styles.button} ${colors.buttonNeutralLight}`}
        onClick={toggleMute}
        type='button'
      >
        <FontAwesomeIcon
          className={styles.buttonIcon}
          icon={localMute ? faVideo : faVideoSlash}
        />
        <div className={styles.buttonLabel}>
          {localMute ? 'Unmute' : 'Mute'}
        </div>
      </button>
    </div>
  );
};

export default MuteButton;

import { useContext, useEffect } from 'react';
import { getAuthedUser } from '../utils/cognito';
import cognitoContext, { AuthState } from '../context/cognito';

const useCheckAuth = () => {
  const { setUser, setAuthState } = useContext(cognitoContext);

  useEffect(() => {
    const checkStatus = async () => {
      const user = await getAuthedUser();
      if (user) {
        setUser(user);
        setAuthState(AuthState.AUTHED);
      } else {
        setAuthState(AuthState.UNAUTHED);
      }
    };

    checkStatus();
  }, [setAuthState, setUser]);
};

export default useCheckAuth;

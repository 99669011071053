import React from 'react';

import { CSSTransition } from 'react-transition-group';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { VideoState } from '../../store/interface';

import fade from '../../common/transitions/fade.module.css';
import styles from './styles.module.css';

import MuteButton from './MuteButton';
import HangUpButton from './HangUpButton';

const ActionBar = () => {
  const videoState = useSelector(
    (state: RootState) => state.interface.videoState
  );

  const show = videoState === VideoState.IN_CALL;

  return (
    <CSSTransition mountOnEnter classNames={fade} in={show} timeout={300}>
      <div className={styles.container}>
        <MuteButton />
        <HangUpButton />
      </div>
    </CSSTransition>
  );
};

export default ActionBar;

import React from 'react';
import { CircleLoader } from 'react-spinners';

import colors from '../../common/colors.module.css';
import styles from './styles.module.css';

const Loading = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <CircleLoader color={colors.sapphireBlue} size={200} />
    </div>
  );
};

export default Loading;

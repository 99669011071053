/* eslint-disable no-console */
import { useContext, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { updateDeviceList } from '../store/webrtc';

import cognitoContext from '../context/cognito';

import { getLocalMedia, stopStream } from '../utils/webrtc';

const useLocalVideo = () => {
  const [localStream, setLocalStream] = useState<MediaStream>();
  const { isAuthed } = useContext(cognitoContext);
  const dispatch = useDispatch();

  const { selectedCamera, selectedMicrophone, selectedSpeaker } = useSelector(
    (state: RootState) => state.webrtc
  );

  useEffect(() => {
    if (isAuthed) {
      stopStream(localStream);
      getLocalMedia(
        true,
        selectedCamera !== undefined ? selectedCamera.value : undefined,
        selectedMicrophone !== undefined ? selectedMicrophone.value : undefined,
        selectedSpeaker !== undefined ? selectedSpeaker.value : undefined
      )
        .then((stream: MediaStream) => {
          dispatch(updateDeviceList());
          navigator.mediaDevices.ondevicechange = () => {
            dispatch(updateDeviceList());
          };

          setLocalStream(stream);
        })
        .catch(err => console.log(err));
    } else {
      stopStream(localStream);
      setLocalStream(undefined);
    }

    return () => {
      if (!isAuthed) {
        if (localStream !== undefined) {
          stopStream(localStream);
          setLocalStream(undefined);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthed, selectedCamera, selectedSpeaker, selectedMicrophone]);

  return localStream;
};

export default useLocalVideo;

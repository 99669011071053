import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideoSlash } from '@fortawesome/free-solid-svg-icons';

import { CSSTransition } from 'react-transition-group';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import { VideoState } from '../../store/interface';

import localVideo from '../../common/transitions/localVideo.module.css';
import styles from './styles.module.css';

import Video from '../Video';

interface Props {
  localVideoStream: MediaStream;
}

const LocalVideo = (props: Props) => {
  const { localVideoStream } = props;

  const { videoState, localMute } = useSelector(
    (state: RootState) => state.interface
  );

  const show = videoState === VideoState.IN_CALL;

  return (
    <CSSTransition classNames={localVideo} in={show} timeout={300}>
      <div className={styles.container}>
        {localVideoStream && !localMute && (
          <Video muted srcObject={localVideoStream} />
        )}
        {localMute && (
          <div className={styles.mute}>
            <FontAwesomeIcon icon={faVideoSlash} className={styles.muteIcon} />
            muted
          </div>
        )}
      </div>
    </CSSTransition>
  );
};

export default LocalVideo;

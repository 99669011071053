import React from 'react';
import { BeatLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';

import { SwitchTransition, CSSTransition } from 'react-transition-group';

import showMessage from '../../common/transitions/showMessage.module.css';
import hideMessage from '../../common/transitions/hideMessage.module.css';

import colors from '../../common/colors.module.css';
import styles from './message.module.css';

interface Props {
  show: boolean;
  type: 'attempting' | 'error' | 'info';
  message?: string;
}

export default React.memo((props: Props) => {
  const { show, type, message } = props;

  return (
    <SwitchTransition mode='out-in'>
      {(show && (type === 'error' || type === 'info') && (
        <CSSTransition
          key={`${show}_${type}_${message}`}
          mountOnEnter
          unmountOnExit
          classNames={showMessage}
          timeout={300}
        >
          <div className={styles.container}>
            <div
              className={`${styles.messageBox} ${
                type === 'info' && styles.messageBoxSuccess
              }`}
            >
              <FontAwesomeIcon
                className={styles.icon}
                icon={type === 'error' ? faExclamationTriangle : faThumbsUp}
              />
              <div>{message}</div>
            </div>
          </div>
        </CSSTransition>
      )) ||
        (show && type === 'attempting' && (
          <CSSTransition
            key='attempting'
            mountOnEnter
            unmountOnExit
            classNames={showMessage}
            timeout={300}
          >
            <div className={styles.container}>
              <div className={styles.spinner}>
                <div>
                  <BeatLoader
                    size={30}
                    color={colors.sapphireBlue}
                    margin={5}
                  />
                </div>
              </div>
            </div>
          </CSSTransition>
        )) || (
          <CSSTransition
            key='hidden'
            mountOnEnter
            classNames={hideMessage}
            timeout={300}
          >
            <div className={styles.container} />
          </CSSTransition>
        )}
    </SwitchTransition>
  );
});

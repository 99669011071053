import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import styles from './styles.module.css';

import { getUserInfo, UserInfo } from '../../utils/cognito';

import Dialog, { ButtonType } from '../Dialog';
import Modal from './Modal';

const UserSettings = () => {
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showConfirmLogoff, setShowConfirmLogoff] = useState<boolean>(false);

  useEffect(() => {
    getUserInfo().then(data => {
      setUserInfo(data);
    });
  }, []);

  return (
    <>
      <div
        role='none'
        className={styles.container}
        onClick={() => setShowModal(!showModal)}
        onKeyPress={() => setShowModal(!showModal)}
      >
        Hello, {userInfo !== undefined && userInfo.firstName}!
        <FontAwesomeIcon icon={faChevronCircleDown} className={styles.icon} />
      </div>

      <Modal show={showModal} />
      <Dialog
        show={showConfirmLogoff}
        message='hey'
        title='boo'
        buttons={[
          {
            type: ButtonType.NEGATIVE,
            name: 'close me',
            handler: () => {
              setShowConfirmLogoff(false);
            },
          },
        ]}
      />
    </>
  );
};

export default UserSettings;

import axios from 'axios';

const memo = require('async-memoize-one');

export interface LngLat {
  longitude: number;
  latitude: number;
}

const key = process.env.REACT_APP_GOOGLE_API_KEY;

if (key === undefined) {
  throw new Error(
    'REACT_APP_GOOGLE_API_KEY env var is undefined. To create one: https://developers.google.com/maps/documentation/javascript/get-api-key'
  );
}

export interface PatronAddress {
  state?: string;
  city?: string;
  address?: string;
}

interface GoogleLngLat {
  lng: string;
  lat: string;
}

interface Bounds {
  northeast: GoogleLngLat;
  southwest: GoogleLngLat;
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface GoogleReverseGeocodeResult {
  address_components: AddressComponent[];
  formatted_address: string;
  geometry: {
    bounds: Bounds;
    location: { lat: string; lng: string };
    location_type: string;
    viewport: Bounds;
  };
  place_id: string;
  types: string[];
}

interface GoogleReverseGeocode {
  plus_code: {
    compound_code: string;
    global_code: string;
  };
  results: GoogleReverseGeocodeResult[];
}

const latLng = ({ longitude, latitude }: LngLat) => `${latitude},${longitude}`;

const filterResult = (data?: GoogleReverseGeocode) => {
  if (data !== undefined) {
    const result = data.results.find(result =>
      result.types.some(type => type === 'street_address' || 'route')
    );

    return result;
  }
  return undefined;
};

const getStreetAddress = (result: GoogleReverseGeocodeResult) => {
  const number = result.address_components.find(addressComponent =>
    addressComponent.types.some(type => type === 'street_number')
  );

  const route = result.address_components.find(addressComponent =>
    addressComponent.types.some(type => type === 'route')
  );

  if (route === undefined) {
    return undefined;
  }

  if (number === undefined) {
    return route.long_name;
  }

  return `${number.long_name} ${route.long_name}`;
};

const getState = (result: GoogleReverseGeocodeResult) => {
  const state = result.address_components.find(addressComponent =>
    addressComponent.types.some(type => type === 'administrative_area_level_1')
  );
  if (state !== undefined) {
    return state.short_name;
  }
  return undefined;
};

const getCity = (result: GoogleReverseGeocodeResult) => {
  const city = result.address_components.find(addressComponent =>
    addressComponent.types.some(type => type === 'locality')
  );
  if (city !== undefined) {
    return city.long_name;
  }

  return undefined;
};

const fetchAddress = async (
  location: LngLat
): Promise<PatronAddress | undefined> => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng(
    location
  )}&key=${key}`;

  const result = await axios.get<GoogleReverseGeocode>(url);

  const filteredResult = result ? filterResult(result.data) : undefined;

  if (filteredResult !== undefined) {
    console.log(filteredResult);
    return {
      address: getStreetAddress(filteredResult),
      city: getCity(filteredResult),
      state: getState(filteredResult),
    };
  }

  return undefined;
};

export const getAddress: (location: LngLat) => Promise<PatronAddress> = memo(
  fetchAddress
);

export const getMiniMap = (location: LngLat) => {
  const url = `https://maps.googleapis.com/maps/api/staticmap?center=${latLng(
    location
  )}&maptype=hybrid&zoom=19&scale=3&size=650x650&key=${key}`;
  return url;
};

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import styles from './styles.module.css';

interface Props {
  accept: () => void;
  decline: () => void;
}

const Buttons = (props: Props): JSX.Element => {
  const { accept, decline } = props;

  return (
    <div className={styles.buttonsContainer}>
      <div className={styles.buttonContainer}>
        <button
          onClick={decline}
          type='button'
          className={`${styles.button} ${styles.decline}`}
        >
          <FontAwesomeIcon icon={faTimes} className={styles.icon} />
          Decline
        </button>
      </div>
      <div className={styles.buttonContainer}>
        <button
          onClick={accept}
          type='button'
          className={`${styles.button} ${styles.accept}`}
        >
          <FontAwesomeIcon icon={faCheck} className={styles.icon} />
          Accept
        </button>
      </div>
    </div>
  );
};

export default Buttons;

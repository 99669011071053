/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';

import styles from './styles.module.css';

interface Props {
  srcObject: MediaStream;
  muted: boolean;
}

const Video = (props: Props) => {
  const { srcObject, muted } = props;
  const refVideo = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (refVideo.current) {
      refVideo.current.srcObject = srcObject;
    }
  }, [srcObject]);

  return (
    <video
      autoPlay
      playsInline
      muted={muted}
      className={styles.video}
      ref={refVideo}
    />
  );
};

export default Video;

import React from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../../store';
import { SignalerState } from '../../store/interface';
import fade from '../../common/transitions/fade.module.css';

import styles from './styles.module.css';

const ConnectionError = (): JSX.Element => {
  const { signalerState } = useSelector((state: RootState) => state.interface);

  const show =
    signalerState === SignalerState.CONNECTION_ERROR ||
    signalerState === SignalerState.TOKEN_AUTH_FAILED;

  const bannerText =
    signalerState === SignalerState.TOKEN_AUTH_FAILED
      ? 'Token authentication failed'
      : 'connection error';

  return (
    <CSSTransition
      in={show}
      classNames={fade}
      timeout={300}
      mountOnEnter
      unmountOnExit
    >
      <div className={styles.container}>
        <div className={styles.text}>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className={styles.icon}
          />
          <div className={styles.banner}>{bannerText}</div>
          {signalerState !== SignalerState.TOKEN_AUTH_FAILED && (
            <>
              Either the server or your network is down.
              <div className={styles.reattempt}>Attempting to reconnect...</div>
            </>
          )}
          {signalerState === SignalerState.TOKEN_AUTH_FAILED &&
            'The authenication process is misconfigured. Please contact the developers.'}
        </div>
      </div>
    </CSSTransition>
  );
};

export default ConnectionError;

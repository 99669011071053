import { useState, useCallback, useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '../store';
import {
  setVideoState,
  VideoState,
  setRemoteMute,
  setLocalMute,
  setPatronLocation,
  setPatronUserInfo,
} from '../store/interface';

import { IncomingPeer } from './useIncomingPeer';

import {
  createPeer,
  parseInboundReport,
  parseOutboundReport,
  InboundReport,
  OutboundReport,
} from '../utils/webrtc';
import { sendMute, receiveMute } from '../utils/signaler';
import { setInboundStats, setOutboundStats } from '../store/webrtc';

const useRemoteVideo = (
  localStream: MediaStream | undefined,
  incomingPeer: IncomingPeer | undefined
): MediaStream | undefined => {
  const dispatch = useAppDispatch();
  const sock = useRef<SocketIOClient.Socket>();
  const [remoteStream, setRemoteStream] = useState<MediaStream>();
  const localId = useSelector((state: RootState) => state.webrtc.localId);
  const room = useRef<string>();
  const videoState = useSelector(
    (state: RootState) => state.interface.videoState
  );
  const peer = useRef<RTCPeerConnection>();
  const interval = useRef<NodeJS.Timeout>();
  const prevInboundReport = useRef<InboundReport>();
  const prevOutboundReport = useRef<OutboundReport>();

  const { localMute } = useSelector((state: RootState) => state.interface);

  const closePeer = useCallback(() => {
    if (peer.current !== undefined) {
      peer.current.close();
      peer.current = undefined;
    }

    dispatch(setLocalMute(false));
    dispatch(setRemoteMute(false));
    dispatch(setPatronLocation(undefined));
    dispatch(setPatronUserInfo(undefined));
  }, [dispatch]);

  useEffect(() => {
    if (room.current !== undefined && sock.current !== undefined) {
      sendMute(sock.current, room.current, {
        video: localMute,
        audio: localMute,
      });
    }

    if (localStream !== undefined) {
      const tracks = localStream.getTracks();

      tracks.forEach(track => {
        // eslint-disable-next-line no-param-reassign
        track.enabled = !localMute;
      });
    }
  }, [localMute, localStream]);

  useEffect(() => {
    if (incomingPeer !== undefined) {
      const { socket, iceConfig, roomName, peerId } = incomingPeer;

      sock.current = socket;

      const newPeer = createPeer(
        socket,
        iceConfig,
        roomName,
        localId,
        peerId,
        (stream: MediaStream) => {
          if (stream !== remoteStream) {
            console.log('got remote stream!');
            setRemoteStream(stream);
            dispatch(setVideoState(VideoState.IN_CALL));
          }
        }
      );

      newPeer.onconnectionstatechange = () => {
        const collectStats = () => {
          newPeer
            .getStats(null)
            .then(stats =>
              stats.forEach(report => {
                if (report.type === 'inbound-rtp' && report.kind === 'video') {
                  const stats = parseInboundReport(
                    prevInboundReport.current,
                    report
                  );
                  prevInboundReport.current = report;
                  dispatch(setInboundStats(stats));
                }

                if (report.type === 'outbound-rtp' && report.kind === 'video') {
                  const stats = parseOutboundReport(
                    prevOutboundReport.current,
                    report
                  );
                  prevOutboundReport.current = report;
                  dispatch(setOutboundStats(stats));
                }
              })
            )
            .catch(e => console.error(e));
        };

        switch (newPeer.connectionState) {
          case 'connected':
            // The connection has become fully connected

            interval.current = setInterval(collectStats, 1000);
            collectStats();
            break;
          default:
            // The connection has been closed
            if (interval.current !== undefined) {
              clearInterval(interval.current);
              interval.current = undefined;
            }
            dispatch(setInboundStats(undefined));
            dispatch(setOutboundStats(undefined));
            break;
        }
      };

      room.current = roomName;

      receiveMute(socket, (_peerId, flags) => {
        dispatch(setRemoteMute(flags.video));
      });

      if (localStream !== undefined) {
        localStream.getTracks().forEach(track => newPeer.addTrack(track));
      }

      peer.current = newPeer;
    } else {
      setRemoteStream(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingPeer]);

  useEffect(() => {
    if (
      localStream !== undefined &&
      peer.current?.connectionState === 'connected'
    ) {
      const senders = peer.current.getSenders();
      senders.forEach(sender => {
        if (sender.track !== null) {
          const { kind } = sender.track;
          const newTrack = localStream
            .getTracks()
            .find(track => track.kind === kind);

          if (newTrack !== undefined) {
            sender.replaceTrack(newTrack);
          }
        }
      });
    }
  }, [localStream]);

  useEffect(() => {
    if (
      videoState === VideoState.NOT_IN_CALL ||
      videoState === VideoState.ON_STANDBY
    ) {
      setRemoteStream(undefined);
      closePeer();
    }
  }, [closePeer, incomingPeer, videoState]);

  return remoteStream;
};

export default useRemoteVideo;

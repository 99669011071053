import React from 'react';

import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import fade from '../../common/transitions/fade.module.css';

import styles from './passwordPolicy.module.css';

interface Props {
  satisfied: boolean;
  label: string;
}

const PasswordPolicy = (props: Props): JSX.Element => {
  const { satisfied, label } = props;

  const icon = satisfied ? faCheck : faTimes;

  const container = `${styles.container} ${
    satisfied && styles.satisfiedContainer
  }`;

  return (
    <SwitchTransition mode='out-in'>
      <CSSTransition key={satisfied.toString()} classNames={fade} timeout={300}>
        <div className={container}>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon icon={icon} className={styles.icon} />
          </div>
          <div className={styles.text}>{label}</div>
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default PasswordPolicy;

import React, { useState, useContext } from 'react';
import cognitoContext, { AuthState } from '../../context/cognito';

import useTimeout from '../../hooks/useTimeout';

import { forgotPasswordCode } from '../../utils/cognito';

import TextInput from './TextInput';

import { testPassword, errorMessage } from '../../utils/password';
import MessageBox from './MessageBox';

import PasswordPolicyGroup from './PasswordPolicyGroup';

import styles from './styles.module.css';

const disableSubmit = (e: any) => {
  e.preventDefault();
};
const ForgotPasswordCode = (): JSX.Element => {
  const { authState, setAuthState, user, setUser } = useContext(cognitoContext);

  const [attempting, setAttempting] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [messageFirstLine, setMessageFirstLine] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const showMessageBox =
    attempting ||
    authState === AuthState.FORGOT_PASSWORD_INVALID_PASSWORD ||
    authState === AuthState.FORGOT_PASSWORD_INVALID_CODE;

  const closeMessageBox = () => {
    setAuthState(AuthState.FORGOT_PASSWORD_EMAIL_SENT);
  };

  useTimeout(showMessageBox, closeMessageBox, 2000);

  const handleCreatePassword = async () => {
    const validity = testPassword(newPassword, confirmPassword);

    if (validity === 'success') {
      setAttempting(true);
      const result = await forgotPasswordCode(user, code, newPassword);
      setAttempting(false);
      if (result !== 'codeMismatch') {
        setUser(undefined);
        setAuthState(AuthState.FORGOT_PASSWORD_SUCCESS_CHANGED);
      } else {
        // whoopsies... let's see what kind of errors we get if code or user are wrong
        setAuthState(AuthState.FORGOT_PASSWORD_INVALID_CODE);
        setMessageFirstLine('Invalid verification code. Please try again.');
      }
    } else {
      setAuthState(AuthState.FORGOT_PASSWORD_INVALID_PASSWORD);
      setMessageFirstLine(errorMessage[validity]);
    }
  };

  const handleCancel = () => {
    setAuthState(AuthState.UNAUTHED);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={`${styles.banner} ${styles.bannerForgotPassword}`}>
          Forgot Password
        </div>
        <div className={styles.instructions}>
          Please check your inbox for verification code.
        </div>
      </div>

      <form onSubmit={disableSubmit}>
        <TextInput type='code' label='Code' value={code} setValue={setCode} />

        <div className={styles.passwordPolicyGroup}>
          <PasswordPolicyGroup
            newPassword={newPassword}
            confirmPassword={confirmPassword}
          />
        </div>

        <TextInput
          type='password'
          label='New Password'
          value={newPassword}
          setValue={setNewPassword}
        />

        <TextInput
          type='password'
          label='Confirm Password'
          value={confirmPassword}
          setValue={setConfirmPassword}
        />

        <MessageBox
          type={attempting ? 'attempting' : 'error'}
          show={showMessageBox}
          message={messageFirstLine}
        />

        <button
          type='submit'
          className={styles.button}
          onClick={handleCreatePassword}
        >
          Create password
        </button>
        <button
          type='button'
          className={`${styles.button} ${styles.buttonNegative}`}
          onClick={handleCancel}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

export default ForgotPasswordCode;

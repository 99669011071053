import React, { useState, useCallback, useContext, useEffect } from 'react';

import cognitoContext, { AuthState } from '../../context/cognito';
import useTimeout from '../../hooks/useTimeout';

import { login } from '../../utils/cognito';

import TextInput from './TextInput';
import MessageBox from './MessageBox';
// import logo from '../../assets/openvri-logo.svg';
import styles from './styles.module.css';
import packa from '../../../package.json';

const logo = require(`../../assets/${
  process.env.REACT_APP_LABEL || ''
}/logo.svg`).default;

const disableSubmit = (e: any) => {
  e.preventDefault();
};

const Login = () => {
  const [attempting, setAttempting] = useState<boolean>(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState<string>('');
  const { authState, setAuthState, setUser } = useContext(cognitoContext);

  const closeMessageBox = useCallback(() => {
    setAuthState(AuthState.UNAUTHED);
    setUser(undefined);
  }, [setAuthState, setUser]);

  const showMessageBox =
    attempting ||
    authState === AuthState.LOGIN_ERROR ||
    authState === AuthState.FORGOT_PASSWORD_SUCCESS_CHANGED;

  useEffect(() => {
    if (authState === AuthState.FORGOT_PASSWORD_SUCCESS_CHANGED) {
      setMessage(
        'Your password is successfully changed! You may try logging in now.'
      );
    }
  }, [authState]);

  useTimeout(
    showMessageBox,
    closeMessageBox,
    authState === AuthState.FORGOT_PASSWORD_SUCCESS_CHANGED ? 5000 : 2000
  );

  const handleLogin = async () => {
    if (username.length === 0 || password.length === 0) {
      setMessage('One of fields is empty.');
      setAuthState(AuthState.LOGIN_ERROR);
    } else {
      setAttempting(true);
      const result = await login(username, password);
      setAttempting(false);

      if (result === 'authError') {
        setMessage('Incorrect username or password.');
        setAuthState(AuthState.LOGIN_ERROR);
      } else if (result === 'limitExceeded') {
        setMessage('Login attempts exceeded limit.');
        setAuthState(AuthState.LOGIN_ERROR);
      } else if (result === 'unknown') {
        setMessage('Unknown error. Please try again later.');
        setAuthState(AuthState.LOGIN_ERROR);
      } else if (result === 'resetRequired') {
        setMessage(
          'Password reset required. Please use the Forgot Password feature.'
        );
        setAuthState(AuthState.LOGIN_ERROR);
      } else if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(result);
        setAuthState(AuthState.NEED_NEW_PASSWORD);
      } else {
        setUser(result);
        setAuthState(AuthState.AUTHED);
      }
    }
  };

  const handleForgot = () => {
    setAuthState(AuthState.FORGOT_PASSWORD);
  };

  const messageBoxType =
    (attempting && 'attempting') ||
    (authState === AuthState.LOGIN_ERROR && 'error') ||
    (authState === AuthState.FORGOT_PASSWORD_SUCCESS_CHANGED && 'info') ||
    'error';

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <img className={styles.logo} alt={'OpenVRI'} src={logo} />
        <div className={styles.banner}>Interpreter Login</div>
        <div className={styles.version}>
          {`${packa.version} - ${process.env.REACT_APP_ENV}`}
        </div>
      </div>
      <div className={styles.loginContainer}>
        <form onSubmit={disableSubmit}>
          <TextInput
            type='user'
            label='Username'
            value={username}
            setValue={setUsername}
          />
          <TextInput
            label='Password'
            value={password}
            setValue={setPassword}
            type='password'
          />
          <MessageBox
            show={showMessageBox}
            message={message}
            type={messageBoxType}
          />
          <button className={styles.button} type='submit' onClick={handleLogin}>
            Login
          </button>
        </form>
        <div className={styles.forgotPassword}>
          <button
            type='button'
            className={styles.forgotPasswordButton}
            onClick={handleForgot}
          >
            I forgot my password
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;

import { useEffect, useReducer, useRef } from 'react';

interface CountState {
  count: number;
}

interface CountAction {
  type: 'reset' | 'decrement';
  payload?: number;
}

function reducer(state: CountState, action: CountAction) {
  switch (action.type) {
    case 'reset':
      if (action.payload !== undefined) {
        return { count: action.payload };
      }
      return { count: 0 };
    case 'decrement':
      return { count: state.count - 1 };
    default:
      throw new Error();
  }
}

const useCountdown = (
  active: boolean,
  initialCount: number,
  handler: () => void
) => {
  const [state, dispatch] = useReducer(reducer, {
    count: initialCount,
  });
  const timeout = useRef<number>();

  const { count } = state;

  useEffect(() => {
    if (active) {
      clearTimeout(timeout.current);
      if (count > 0) {
        timeout.current = (setTimeout(() => {
          dispatch({ type: 'decrement' });
        }, 1000) as unknown) as number;
      } else {
        handler();
      }
    }
  }, [handler, active, count]);

  useEffect(() => {
    if (active) {
      dispatch({ type: 'reset', payload: initialCount });
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, [active, initialCount]);

  return count;
};

export default useCountdown;

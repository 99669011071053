import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { VideoState } from '../store/interface';

const listener = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  // eslint-disable-next-line no-param-reassign
  event.returnValue =
    'You have an ongoing call. Are you sure you want to close?';
  return event.returnValue;
};

const useCloseConfirmation = () => {
  const { videoState } = useSelector((state: RootState) => state.interface);

  const shouldBlock =
    videoState !== VideoState.NOT_IN_CALL &&
    videoState !== VideoState.ON_STANDBY;

  useEffect(() => {
    if (shouldBlock) {
      window.addEventListener('beforeunload', listener);
    }

    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [shouldBlock, videoState]);
};

export default useCloseConfirmation;
